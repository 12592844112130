import * as React from "react";
import PageComponent from "src/components/pages/404";
import { Link } from "gatsby";

const Page = ({ location }) => {
  const lang = location.pathname.startsWith("/he") ? "he" : "en";

  const copy = {
    en: {
      title: "404 - Page Not Found",
      header: `There's nothing here`,
      text: (
        <p>
          Perhaps you followed an outdated link?{" "}
          <Link className="text-blue-light underline hover:no-underline" to="/">
            Click here
          </Link>{" "}
          to go to the home page.
        </p>
      ),
    },
    he: {
      title: "404 - הדף אינו נמצא",
      header: `אין כאן כלום`,
      text: (
        <p>
          אולי לחצתם על קישור ישן?{" "}
          <Link
            className="text-blue-light underline hover:no-underline"
            to="/he"
          >
            לחצו כאן
          </Link>{" "}
          להמשיך לדף הבית.
        </p>
      ),
    },
  };
  return (
    <PageComponent
      lang={lang}
      location={location}
      copy={copy[lang]}
      pageTitle={copy[lang].title}
    />
  );
};

export default Page;
