import * as React from "react";
import Layout from "src/components/layout";

const PageComponent = ({ location, copy, pageTitle, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto my-20 text-center">
        <h2 className="mb-4">{copy.header}</h2>
        {copy.text}
      </section>
    </Layout>
  );
};

export default PageComponent;
